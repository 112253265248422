import './App.css';
import moment from "moment";

import { useEffect, useState } from 'react';
import axios from "axios";

function App() {
  const [timeNow, setTimeNow] = useState(new Date());
  const [courts, setCourts] = useState([]);
  const version = 28
  const this_court = parseInt(window.location.pathname.substring(1), 10) && parseInt(window.location.pathname.substring(1), 10) <= 5 ? parseInt(window.location.pathname.substring(1), 10) : 1

  function getCourts() {
    axios.get(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : '/'}courts.json`)
      .then(({ data }) => {
        if (data) {
          setCourts(data);
          if (data.version > version) {
            window.location.reload(true);
          }
        }
      });
  }

  function nextkey(courts, this_court) {
    const nowTime = parseInt(moment.utc(timeNow).format("HHmm"), 10)
    let keys = Object.keys(courts[`court${this_court}`].court_times).sort((a, b) => parseInt(a, 10) > parseInt(b, 10) ? 1 : -1)
    for (var key of keys) {
      if (parseInt(key, 10) > nowTime) {
        return key
      }
    }
  }

  function nowkey(courts, this_court, next) {
    let keys = Object.keys(courts[`court${this_court}`].court_times).sort((a, b) => parseInt(a, 10) > parseInt(b, 10) ? 1 : -1)
    let nextIndex = keys.indexOf(next)
    return nextIndex >= 0 ? keys[nextIndex - 1] : ''
  }

  function players(details) {
    if (details) {
      if (details === "Free") {
        return (<p>Free</p>)
      } else {
        return details.split(",").map((p, index) => {
          return (
            <p key={index}>{p}</p>
          )
        })
      }
    } else {
      return (<p>Closed</p>)
    }
  }

  useEffect(() => {
    let refreshInterval = setInterval(() => {
      setTimeNow(new Date())
    }, 1000);
    return () => clearInterval(refreshInterval);
  }, []);

  useEffect(() => {
    let getCourtsInterval = setInterval(() => {
      getCourts()
    }, 60000 * 1);
    return () => clearInterval(getCourtsInterval);
  }, []);

  useEffect(() => {
    getCourts();
  }, []);

  if (courts.length === 0) {
    return (<p>Loading...</p>)
  } else {
    let _next = nextkey(courts, this_court)
    let _now = nowkey(courts, this_court, _next)
    let _nextPlayer = players(courts[`court${this_court}`].court_times[_next])

    return (
      <div className="App">
        <table width="100%">
          <tbody>
            <tr><td colSpan="2" className="clock">{moment.utc(timeNow).format("dddd, MMMM DD HH:mm:ss")}</td></tr>
            <tr><td><img src={`./images/${courts[`court${this_court}`].Logo}`} width="504" height="127" alt="" /></td><td><img src={'./images/BSCLogo.png'} width="504" height="127" alt="" /></td></tr>
            <tr><td colSpan="2" className="court-title">Court {courts[`court${this_court}`].court_number} {courts[`court${this_court}`].court_name ? `(${courts[`court${this_court}`].court_name})` : ""}</td></tr>
            {courts[`court${this_court}`].court_times[_now] ?
              <>
                <tr><td className="titles">Now {_now}</td><td className="titles">Next {_next}</td></tr>
                <tr><td align="center"><hr width={`70%`} /></td><td align="center"><hr width={`70%`} /></td></tr>
                <tr height={240}>
                  <td className={`details-${courts[`court${this_court}`].court_times[_now].length <= 12 ? 'large' : 'normal'}`}>{players(courts[`court${this_court}`].court_times[_now])}</td>
                  <td className={`details-${courts[`court${this_court}`].court_times[_next].length <= 12 ? 'large' : 'normal'}`}>{_nextPlayer}</td>
                </tr>
              </>
              :
              <>
              <tr><td colSpan="2" className="titles" height="300">
                Court Closed
                </td></tr>
              </>
            }

          </tbody>
        </table>
      </div>
    );
  }
}

export default App;
